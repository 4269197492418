<template>
  <div class="confirm-custody">
    <header>
      <div class="subheadline-large">
        {{ details.title }}
      </div>

      <div
        class="body-text-large mt-s24"
        v-html="details.description"
      />
    </header>

    <div class="flex flex-col items-end gap-s16 mt-s24">
      <ButtonV2
        @onClick="onRemove"
        :label="details.confirm"
        :loading="loading"
        version="primary"
        size="medium"
        wide
      />

      <ButtonV2
        v-if="details.cancel"
        @onClick="hideModal"
        class="capitalize"
        :label="details.cancel"
        :loading="loading"
        size="medium"
        version="secondary"
        wide
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ButtonV2 } from '@/components/misc';
import SUBMIT_FEEDBACK from '@/graphql/mutations/SubmitFeedback.gql';

export default {
  name: 'TakeCustodyModal',

  components: { ButtonV2 },

  props: {
    type: {
      type: String,
      required: true,
    },

    feedback: {
      pairId: {
        type: String,
        required: true,
      },
      cardName: {
        type: String,
        required: true,
      },
      cardPrice: {
        type: String,
        required: true,
      },
      pwccIdentityKey: {
        type: String,
        required: true,
      },
    },
  },
  
  data() {
    return {
      loading: false,
      modalType: this.type,
    };
  },

  computed: {
    isConfirm() {
      return this.modalType === 'confirm';
    },

    details() {
      const cancel = this.isConfirm
        ? this.$t(`take_custody.modal.${ this.modalType }.cancel`)
        : null;

      return {
        title: this.$t(`take_custody.modal.${ this.modalType }.title`),
        description: this.$t(`take_custody.modal.${ this.modalType }.description`),
        confirm: this.$t(`take_custody.modal.${ this.modalType }.confirm`),
        cancel,
      };
    }
  },

  mounted() {
    this.setModalProps({
      type: this.modalType,
      hasPrevious: false,
      isClosable: false,
    });
  },

  methods: {
    ...mapActions('ui', ['setModalProps']),

    async onRemove() {
      if (!this.isConfirm) {
        this.hideModal();
        this.$router.push('/');
        return;
      }

      try {
        this.loading = true;

        await this.$apollo.mutate({
          mutation: SUBMIT_FEEDBACK,
          variables: {
            feedback: this.getFeedback(),
          },
        });

        this.modalType = 'success';
        
        localStorage.removeItem('takeCustodyAsset');
      } catch (err) {
        await this.showError(err);
      } finally {
        this.loading = false;
      }
    },

    getFeedback() {
      const { pairId, cardName, cardPrice, pwccIdentityKey } = this.feedback;
      const { option } = this.$route.params;

      const messages = {
        vault: `\nCARD WITHDRAW REQUEST:\nPair ID: ${pairId}\nAsset Name: ${cardName}\nAsset Price: ${cardPrice}\nWithdraw Method: Vault PWCC\nPWCC Identity Key: ${pwccIdentityKey}`,
        ship: `\nCARD WITHDRAW REQUEST:\nPair ID: ${pairId}\nAsset Name: ${cardName}\nAsset Price: ${cardPrice}\nWithdraw Method: Shipping`,
      };

      return messages[option];
    }
  }
};
</script>

<style scoped>
.confirm-custody {
  @apply mt-s16 flex flex-col justify-between;
  height: auto;
  max-height: 340px;
  max-width: 100%;
  width: 350px;
}
</style>
